import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Remote Support",
  "description": "Probleme auf Ihrem Gerät? So können wir Ihnen unkompliziert helfen",
  "author": "Jochen",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Fordern Sie schnelle Hilfe an mit dem inriva Remote Support`}</p>
    <p>{`Damit wir Ihnen direkt am Bildschirm helfen können bieten wir Ihnen die inriva-QuickSupport App an.`}</p>
    <a href="http://get.teamviewer.com/inrivasupport">Laden Sie das Programm einfach auf Ihren PC</a> und klicken Sie auf Start.
Sobald Sie unserem Mitarbeiter die Support-Kennung mitgeteilt haben, können wir uns gemeinsam mit Ihnen am Bildschirm Ihr Anliegen ansehen.
    <p>{`Für PC/Mac: Jetzt inriva QuickSupport herunterladen`}</p>
    <p>{`Für ihr Android Smartphone (Samsung Galaxy etc.): `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market"
      }}>{`https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market`}</a></p>
    <p>{`Für ihr iPhone:
`}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/teamviewer-quicksupport/id661649585"
      }}>{`https://itunes.apple.com/us/app/teamviewer-quicksupport/id661649585`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      